import React, { useState, useEffect } from 'react';
import { githubLight } from '@uiw/codemirror-theme-github';
import CodeMirror from '@uiw/react-codemirror';
import { generateCurlCommand } from '../../utils/generateCurl';

/**
 * Component to display a cURL command for a dataset
 */
const CurlCommandView = ({ dataset }) => {
  const [curlCommand, setCurlCommand] = useState('');

  useEffect(() => {
    if (dataset) {
      const generatedCommand = generateCurlCommand(dataset);
      setCurlCommand(generatedCommand);
    }
  }, [dataset]);

  return (
    <div className="curl-command-view">
      <div className="curl-content border border-gray-300 rounded-md overflow-hidden">
        <CodeMirror
          readOnly
          value={curlCommand}
          height="auto"
          minHeight="100px"
          maxHeight="750px"
          theme={githubLight}
        />
      </div>
    </div>
  );
};

export default CurlCommandView;