/**
 * Generates a cURL command string from dataset JSON
 * @param {Object} dataset - The dataset object containing JSON data
 * @returns {string} - Formatted cURL command
 */
import { config } from './config';

export const generateCurlCommand = (dataset) => {
  if (!dataset || !dataset.datasetJson) {
    return '';
  }

  try {
    const datasetObj = JSON.parse(dataset.datasetJson);
    
    // Set method to POST as required
    const method = 'POST';
    
    // Set URL to {config.apiBaseUrl}/Integrations/dialog/json
    const url = `${config.apiBaseUrl}/Integrations/dialog/json`;
    
    // Start building the cURL command
    let curlCommand = `curl --location '${url}' \\\n`;
    
    // Add authorization header
    curlCommand += `--header 'authorization: Bearer {yourAccessToken}' \\\n`;
    
    // Add content-type header
    curlCommand += `--header 'content-type: application/json' \\\n`;
    
    // Add POST method
    curlCommand += `--request ${method} \\\n`;
    
    // Add datasetObj as the body
    const bodyData = JSON.stringify(datasetObj, null, 2);
    curlCommand += `--data-raw '${bodyData}'`;

    return curlCommand;
  } catch (error) {
    console.error('Error generating cURL command:', error);
    return '# Error generating cURL command. Please check if the dataset contains valid JSON.';
  }
};