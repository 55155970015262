export const pleaseWait = [
    {
        "code": "en",
        "message": "Please wait",
    },
    {
        "code": "no",
        "message": "Vennligst vent",
    },
    {
        "code": "sv",
        "message": "Vänligen vänta",
    },
    {
        "code": "fi",
        "message": "Odota hetki",
    },
    {
        "code": "da",
        "message": "Vent venligst",
    },
    {
        "code": "is",
        "message": "Vinsamlegast bíðið",
    },
    {
        "code": "es",
        "message": "Por favor espera",
    },
    {
        "code": "fr",
        "message": "Veuillez patienter",
    },
    {
        "code": "it",
        "message": "Attendere prego",
    },
    {
        "code": "ja",
        "message": "お待ちください",
    },
    {
        "code": "pl",
        "message": "Proszę czekać",
    },
    {
        "code": "pt",
        "message": "Por favor, aguarde",
    }
];

export function getTranslationPleaseWait(languageCode) {
    const translation = pleaseWait.find(entry => entry.code === languageCode);
    return translation ? translation.message : "Please wait";
}