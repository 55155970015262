import * as Constants from '../utils/constants';
import { useSelectInputHelpers } from './useSelectInputHelpers';

export const useTemplateLanguageActions = (templateLanguages) => {
    const selectInputHelpers = useSelectInputHelpers();

    const disabledMapped = (templateLanguages || []).map(x => ({ ...x, name: x.status === Constants.languageListStatus.disabled ? x.name + ' (Disabled)' : x.name, disabled: x.status === Constants.languageListStatus.disabled })) ;

    return {
        getAsSelectOptions: (availableLanguageCodes = []) => {
            // If availableLanguageCodes is provided, filter languages to show only available ones
            let filteredLanguages = [];
            availableLanguageCodes = availableLanguageCodes.findIndex(code => code === 'en') === -1 ? [...availableLanguageCodes, 'en'] : availableLanguageCodes;
            if (availableLanguageCodes && availableLanguageCodes.length > 0) {
                filteredLanguages = disabledMapped.filter(lang => availableLanguageCodes.includes(lang.languageCode));
            }

            // Sort languages alphabetically by name before creating options
            const sortedLanguages = [...filteredLanguages].sort((a, b) => a.name.localeCompare(b.name));
            return selectInputHelpers.createOptions(sortedLanguages, "languageCode", "name");
        }
    }
}