export const countryList = [
     {
        "code": "en",
        "name": "English",
        "nativeName": "English"
    },
    {
        "code": "no",
        "name": "Norwegian",
        "nativeName": "Norsk"
    },
        {
        "code": "sv",
        "name": "Swedish",
        "nativeName": "svenska"
    },
    {
        "code": "fi",
        "name": "Finnish",
        "nativeName": "suomi"
    },
    {
        "code": "da",
        "name": "Danish",
        "nativeName": "dansk"
    },
    {
        "code": "is",
        "name": "Icelandic",
        "nativeName": "Íslenska"
    },
    {
        "code": "es",
        "name": "Spanish",
        "nativeName": "español"
    },
    {
        "code": "fr",
        "name": "French",
        "nativeName": "français"
    },
    {
        "code": "it",
        "name": "Italian",
        "nativeName": "Italiano"
    },
    {
        "code": "ja",
        "name": "Japanese",
        "nativeName": "日本語 (にほんご／にっぽんご)"
    },
    {
        "code": "pl",
        "name": "Polish",
        "nativeName": "polski"
    },
        {
        "code": "pt",
        "name": "Portuguese",
        "nativeName": "Português"
    },
].sort((a, b) => {
    if(a.code === 'en') return -1;
    if(b.code === 'en') return 1;
    
    return a.name.localeCompare(b.name)
});

// all languages we support
// export const countryList = [
//    {
//         "code": "nn",
//         "name": "Nynorsk",
//         "nativeName": "Nynorsk"
//     },
//     {
//         "code": "af",
//         "name": "Afrikaans",
//         "nativeName": "Afrikaans"
//     },
//     {
//         "code": "ak",
//         "name": "Akan",
//         "nativeName": "Akan"
//     },
//     {
//         "code": "sq",
//         "name": "Albanian",
//         "nativeName": "Shqip"
//     },
//     {
//         "code": "am",
//         "name": "Amharic",
//         "nativeName": "አማርኛ"
//     },
//     {
//         "code": "ar",
//         "name": "Arabic",
//         "nativeName": "العربية"
//     },
//     {
//         "code": "hy",
//         "name": "Armenian",
//         "nativeName": "Հայերեն"
//     },
//     {
//         "code": "as",
//         "name": "Assamese",
//         "nativeName": "অসমীয়া"
//     },
//     {
//         "code": "ay",
//         "name": "Aymara",
//         "nativeName": "aymar aru"
//     },
//     {
//         "code": "az",
//         "name": "Azerbaijani",
//         "nativeName": "azərbaycan dili"
//     },
//     {
//         "code": "bm",
//         "name": "Bambara",
//         "nativeName": "bamanankan"
//     },
//     {
//         "code": "eu",
//         "name": "Basque",
//         "nativeName": "euskara, euskera"
//     },
//     {
//         "code": "be",
//         "name": "Belarusian",
//         "nativeName": "Беларуская"
//     },
//     {
//         "code": "bn",
//         "name": "Bengali",
//         "nativeName": "বাংলা"
//     },
//     {
//         "code": "bs",
//         "name": "Bosnian",
//         "nativeName": "bosanski jezik"
//     },
//     {
//         "code": "bg",
//         "name": "Bulgarian",
//         "nativeName": "български език"
//     },
//     {
//         "code": "my",
//         "name": "Burmese",
//         "nativeName": "ဗမာစာ"
//     },
//     {
//         "code": "ca",
//         "name": "Catalan; Valencian",
//         "nativeName": "Català"
//     },
//     {
//         "code": "ny",
//         "name": "Chichewa; Chewa; Nyanja",
//         "nativeName": "chiCheŵa, chinyanja"
//     },
//     {
//         "code": "zh-CN",
//         "name": "Chinese (Simplified)",
//         "nativeName": "中文 (Zhōngwén), 汉语, 漢語"
//     },
//     {
//         "code": "zh-TW",
//         "name": "Chinese (Traditional)",
//         "nativeName": "中文 (Zhōngwén), 汉语, 漢語"
//     },
//     {
//         "code": "co",
//         "name": "Corsican",
//         "nativeName": "corsu, lingua corsa"
//     },
//     {
//         "code": "hr",
//         "name": "Croatian",
//         "nativeName": "hrvatski"
//     },
//     {
//         "code": "cs",
//         "name": "Czech",
//         "nativeName": "česky, čeština"
//     },
//     {
//         "code": "da",
//         "name": "Danish",
//         "nativeName": "dansk"
//     },
//     {
//         "code": "dv",
//         "name": "Divehi; Dhivehi; Maldivian;",
//         "nativeName": "ދިވެހި"
//     },
//     {
//         "code": "nl",
//         "name": "Dutch",
//         "nativeName": "Nederlands, Vlaams"
//     },
//     {
//         "code": "en",
//         "name": "English",
//         "nativeName": "English"
//     },
//     {
//         "code": "eo",
//         "name": "Esperanto",
//         "nativeName": "Esperanto"
//     },
//     {
//         "code": "et",
//         "name": "Estonian",
//         "nativeName": "eesti, eesti keel"
//     },
//     {
//         "code": "ee",
//         "name": "Ewe",
//         "nativeName": "Eʋegbe"
//     },
//     {
//         "code": "fi",
//         "name": "Finnish",
//         "nativeName": "suomi, suomen kieli"
//     },
//     {
//         "code": "fr",
//         "name": "French",
//         "nativeName": "français, langue française"
//     },
//     {
//         "code": "gl",
//         "name": "Galician",
//         "nativeName": "Galego"
//     },
//     {
//         "code": "ka",
//         "name": "Georgian",
//         "nativeName": "ქართული"
//     },
//     {
//         "code": "de",
//         "name": "German",
//         "nativeName": "Deutsch"
//     },
//     {
//         "code": "el",
//         "name": "Greek, Modern",
//         "nativeName": "Ελληνικά"
//     },
//     {
//         "code": "gn",
//         "name": "Guaraní",
//         "nativeName": "Avañeẽ"
//     },
//     {
//         "code": "gu",
//         "name": "Gujarati",
//         "nativeName": "ગુજરાતી"
//     },
//     {
//         "code": "ht",
//         "name": "Haitian; Haitian Creole",
//         "nativeName": "Kreyòl ayisyen"
//     },
//     {
//         "code": "ha",
//         "name": "Hausa",
//         "nativeName": "Hausa, هَوُسَ"
//     },
//     {
//         "code": "he",
//         "name": "Hebrew (modern)",
//         "nativeName": "עברית"
//     },
//     {
//         "code": "hi",
//         "name": "Hindi",
//         "nativeName": "हिन्दी, हिंदी"
//     },
//     {
//         "code": "hu",
//         "name": "Hungarian",
//         "nativeName": "Magyar"
//     },
//     {
//         "code": "is",
//         "name": "Icelandic",
//         "nativeName": "Íslenska"
//     },
//     {
//         "code": "ig",
//         "name": "Igbo",
//         "nativeName": "Asụsụ Igbo"
//     },
//     {
//         "code": "id",
//         "name": "Indonesian",
//         "nativeName": "Bahasa Indonesia"
//     },
//     {
//         "code": "ga",
//         "name": "Irish",
//         "nativeName": "Gaeilge"
//     },
//     {
//         "code": "it",
//         "name": "Italian",
//         "nativeName": "Italiano"
//     },
//     {
//         "code": "ja",
//         "name": "Japanese",
//         "nativeName": "日本語 (にほんご／にっぽんご)"
//     },
//     {
//         "code": "jv",
//         "name": "Javanese",
//         "nativeName": "basa Jawa"
//     },
//     {
//         "code": "kn",
//         "name": "Kannada",
//         "nativeName": "ಕನ್ನಡ"
//     },
//     {
//         "code": "kk",
//         "name": "Kazakh",
//         "nativeName": "Қазақ тілі"
//     },
//     {
//         "code": "km",
//         "name": "Khmer",
//         "nativeName": "ភាសាខ្មែរ"
//     },
//     {
//         "code": "rw",
//         "name": "Kinyarwanda",
//         "nativeName": "Ikinyarwanda"
//     },
//     {
//         "code": "ky",
//         "name": "Kirghiz, Kyrgyz",
//         "nativeName": "кыргыз тили"
//     },
//     {
//         "code": "ko",
//         "name": "Korean",
//         "nativeName": "한국어 (韓國語), 조선말 (朝鮮語)"
//     },
//     {
//         "code": "ku",
//         "name": "Kurdish",
//         "nativeName": "Kurdî, كوردی‎"
//     },
//     {
//         "code": "lo",
//         "name": "Lao",
//         "nativeName": "ພາສາລາວ"
//     },
//     {
//         "code": "la",
//         "name": "Latin",
//         "nativeName": "latine, lingua latina"
//     },
//     {
//         "code": "lv",
//         "name": "Latvian",
//         "nativeName": "latviešu valoda"
//     },
//     {
//         "code": "ln",
//         "name": "Lingala",
//         "nativeName": "Lingála"
//     },
//     {
//         "code": "lt",
//         "name": "Lithuanian",
//         "nativeName": "lietuvių kalba"
//     },
//     {
//         "code": "lg",
//         "name": "Luganda",
//         "nativeName": "Luganda"
//     },
//     {
//         "code": "lb",
//         "name": "Luxembourgish, Letzeburgesch",
//         "nativeName": "Lëtzebuergesch"
//     },
//     {
//         "code": "mk",
//         "name": "Macedonian",
//         "nativeName": "македонски јазик"
//     },
//     {
//         "code": "mg",
//         "name": "Malagasy",
//         "nativeName": "Malagasy fiteny"
//     },
//     {
//         "code": "ms",
//         "name": "Malay",
//         "nativeName": "bahasa Melayu, بهاس ملايو‎"
//     },
//     {
//         "code": "ml",
//         "name": "Malayalam",
//         "nativeName": "മലയാളം"
//     },
//     {
//         "code": "mt",
//         "name": "Maltese",
//         "nativeName": "Malti"
//     },
//     {
//         "code": "mr",
//         "name": "Marathi (Marāṭhī)",
//         "nativeName": "मराठी"
//     },
//     {
//         "code": "mn",
//         "name": "Mongolian",
//         "nativeName": "монгол"
//     },
//     {
//         "code": "mi",
//         "name": "Māori",
//         "nativeName": "te reo Māori"
//     },
//     {
//         "code": "ne",
//         "name": "Nepali",
//         "nativeName": "नेपाली"
//     },
//     {
//         "code": "no",
//         "name": "Norwegian",
//         "nativeName": "Norsk"
//     },
//     {
//         "code": "or",
//         "name": "Oriya",
//         "nativeName": "ଓଡ଼ିଆ"
//     },
//     {
//         "code": "om",
//         "name": "Oromo",
//         "nativeName": "Afaan Oromoo"
//     },
//     {
//         "code": "pa",
//         "name": "Panjabi, Punjabi",
//         "nativeName": "ਪੰਜਾਬੀ, پنجابی‎"
//     },
//     {
//         "code": "ps",
//         "name": "Pashto, Pushto",
//         "nativeName": "پښتو"
//     },
//     {
//         "code": "fa",
//         "name": "Persian",
//         "nativeName": "فارسی"
//     },
//     {
//         "code": "pl",
//         "name": "Polish",
//         "nativeName": "polski"
//     },
//     {
//         "code": "pt",
//         "name": "Portuguese",
//         "nativeName": "Português"
//     },
//     {
//         "code": "qu",
//         "name": "Quechua",
//         "nativeName": "Runa Simi, Kichwa"
//     },
//     {
//         "code": "ro",
//         "name": "Romanian, Moldavian, Moldovan",
//         "nativeName": "română"
//     },
//     {
//         "code": "ru",
//         "name": "Russian",
//         "nativeName": "русский язык"
//     },
//     {
//         "code": "sm",
//         "name": "Samoan",
//         "nativeName": "gagana faa Samoa"
//     },
//     {
//         "code": "sa",
//         "name": "Sanskrit (Saṁskṛta)",
//         "nativeName": "संस्कृतम्"
//     },
//     {
//         "code": "gd",
//         "name": "Scottish Gaelic; Gaelic",
//         "nativeName": "Gàidhlig"
//     },
//     {
//         "code": "sr",
//         "name": "Serbian",
//         "nativeName": "српски језик"
//     },
//     {
//         "code": "sn",
//         "name": "Shona",
//         "nativeName": "chiShona"
//     },
//     {
//         "code": "sd",
//         "name": "Sindhi",
//         "nativeName": "सिन्धी, سنڌي، سندھی‎"
//     },
//     {
//         "code": "si",
//         "name": "Sinhala, Sinhalese",
//         "nativeName": "සිංහල"
//     },
//     {
//         "code": "sk",
//         "name": "Slovak",
//         "nativeName": "slovenčina"
//     },
//     {
//         "code": "sl",
//         "name": "Slovene",
//         "nativeName": "slovenščina"
//     },
//     {
//         "code": "so",
//         "name": "Somali",
//         "nativeName": "Soomaaliga, af Soomaali"
//     },
//     {
//         "code": "st",
//         "name": "Southern Sotho",
//         "nativeName": "Sesotho"
//     },
//     {
//         "code": "es",
//         "name": "Spanish; Castilian",
//         "nativeName": "español, castellano"
//     },
//     {
//         "code": "su",
//         "name": "Sundanese",
//         "nativeName": "Basa Sunda"
//     },
//     {
//         "code": "sw",
//         "name": "Swahili",
//         "nativeName": "Kiswahili"
//     },
//     {
//         "code": "sv",
//         "name": "Swedish",
//         "nativeName": "svenska"
//     },
//     {
//         "code": "tl",
//         "name": "Tagalog",
//         "nativeName": "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"
//     },
//     {
//         "code": "tg",
//         "name": "Tajik",
//         "nativeName": "тоҷикӣ, toğikī, تاجیکی‎"
//     },
//     {
//         "code": "ta",
//         "name": "Tamil",
//         "nativeName": "தமிழ்"
//     },
//     {
//         "code": "tt",
//         "name": "Tatar",
//         "nativeName": "татарча, tatarça, تاتارچا‎"
//     },
//     {
//         "code": "te",
//         "name": "Telugu",
//         "nativeName": "తెలుగు"
//     },
//     {
//         "code": "th",
//         "name": "Thai",
//         "nativeName": "ไทย"
//     },
//     {
//         "code": "ti",
//         "name": "Tigrinya",
//         "nativeName": "ትግርኛ"
//     },
//     {
//         "code": "ts",
//         "name": "Tsonga",
//         "nativeName": "Xitsonga"
//     },
//     {
//         "code": "tr",
//         "name": "Turkish",
//         "nativeName": "Türkçe"
//     },
//     {
//         "code": "tk",
//         "name": "Turkmen",
//         "nativeName": "Türkmen, Түркмен"
//     },
//     {
//         "code": "ug",
//         "name": "Uighur, Uyghur",
//         "nativeName": "Uyƣurqə, ئۇيغۇرچە‎"
//     },
//     {
//         "code": "uk",
//         "name": "Ukrainian",
//         "nativeName": "українська"
//     },
//     {
//         "code": "ur",
//         "name": "Urdu",
//         "nativeName": "اردو"
//     },
//     {
//         "code": "uz",
//         "name": "Uzbek",
//         "nativeName": "zbek, Ўзбек, أۇزبېك‎"
//     },
//     {
//         "code": "vi",
//         "name": "Vietnamese",
//         "nativeName": "Tiếng Việt"
//     },
//     {
//         "code": "cy",
//         "name": "Welsh",
//         "nativeName": "Cymraeg"
//     },
//     {
//         "code": "fy",
//         "name": "Western Frisian",
//         "nativeName": "Frysk"
//     },
//     {
//         "code": "xh",
//         "name": "Xhosa",
//         "nativeName": "isiXhosa"
//     },
//     {
//         "code": "yi",
//         "name": "Yiddish",
//         "nativeName": "ייִדיש"
//     },
//     {
//         "code": "yo",
//         "name": "Yoruba",
//         "nativeName": "Yorùbá"
//     }
// ]

/* export const countryList = [
    {"code":"ab","name":"Abkhaz","nativeName":"аҧсуа"},
    {"code":"aa","name":"Afar","nativeName":"Afaraf"},
    {"code":"af","name":"Afrikaans","nativeName":"Afrikaans"},
    {"code":"ak","name":"Akan","nativeName":"Akan"},
    {"code":"sq","name":"Albanian","nativeName":"Shqip"},
    {"code":"am","name":"Amharic","nativeName":"አማርኛ"},
    {"code":"ar","name":"Arabic","nativeName":"العربية"},
    {"code":"an","name":"Aragonese","nativeName":"Aragonés"},
    {"code":"hy","name":"Armenian","nativeName":"Հայերեն"},
    {"code":"as","name":"Assamese","nativeName":"অসমীয়া"},
    {"code":"av","name":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
    {"code":"ae","name":"Avestan","nativeName":"avesta"},
    {"code":"ay","name":"Aymara","nativeName":"aymar aru"},
    {"code":"az","name":"Azerbaijani","nativeName":"azərbaycan dili"},
    {"code":"bm","name":"Bambara","nativeName":"bamanankan"},
    {"code":"ba","name":"Bashkir","nativeName":"башҡорт теле"},
    {"code":"eu","name":"Basque","nativeName":"euskara, euskera"},
    {"code":"be","name":"Belarusian","nativeName":"Беларуская"},
    {"code":"bn","name":"Bengali","nativeName":"বাংলা"},
    {"code":"bh","name":"Bihari","nativeName":"भोजपुरी"},
    {"code":"bi","name":"Bislama","nativeName":"Bislama"},
    {"code":"bs","name":"Bosnian","nativeName":"bosanski jezik"},
    {"code":"br","name":"Breton","nativeName":"brezhoneg"},
    {"code":"bg","name":"Bulgarian","nativeName":"български език"},
    {"code":"my","name":"Burmese","nativeName":"ဗမာစာ"},
    {"code":"ca","name":"Catalan; Valencian","nativeName":"Català"},
    {"code":"ch","name":"Chamorro","nativeName":"Chamoru"},
    {"code":"ce","name":"Chechen","nativeName":"нохчийн мотт"},
    {"code":"ny","name":"Chichewa; Chewa; Nyanja","nativeName":"chiCheŵa, chinyanja"},
    {"code":"zh","name":"Chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
    {"code":"cv","name":"Chuvash","nativeName":"чӑваш чӗлхи"},
    {"code":"kw","name":"Cornish","nativeName":"Kernewek"},
    {"code":"co","name":"Corsican","nativeName":"corsu, lingua corsa"},
    {"code":"cr","name":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
    {"code":"hr","name":"Croatian","nativeName":"hrvatski"},
    {"code":"cs","name":"Czech","nativeName":"česky, čeština"},
    {"code":"da","name":"Danish","nativeName":"dansk"},
    {"code":"dv","name":"Divehi; Dhivehi; Maldivian;","nativeName":"ދިވެހި"},
    {"code":"nl","name":"Dutch","nativeName":"Nederlands, Vlaams"},
    {"code":"en","name":"English","nativeName":"English"},
    {"code":"eo","name":"Esperanto","nativeName":"Esperanto"},
    {"code":"et","name":"Estonian","nativeName":"eesti, eesti keel"},
    {"code":"ee","name":"Ewe","nativeName":"Eʋegbe"},
    {"code":"fo","name":"Faroese","nativeName":"føroyskt"},
    {"code":"fj","name":"Fijian","nativeName":"vosa Vakaviti"},
    {"code":"fi","name":"Finnish","nativeName":"suomi, suomen kieli"},
    {"code":"fr","name":"French","nativeName":"français, langue française"},
    {"code":"ff","name":"Fula; Fulah; Pulaar; Pular","nativeName":"Fulfulde, Pulaar, Pular"},
    {"code":"gl","name":"Galician","nativeName":"Galego"},
    {"code":"ka","name":"Georgian","nativeName":"ქართული"},
    {"code":"de","name":"German","nativeName":"Deutsch"},
    {"code":"el","name":"Greek, Modern","nativeName":"Ελληνικά"},
    {"code":"gn","name":"Guaraní","nativeName":"Avañeẽ"},
    {"code":"gu","name":"Gujarati","nativeName":"ગુજરાતી"},
    {"code":"ht","name":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
    {"code":"ha","name":"Hausa","nativeName":"Hausa, هَوُسَ"},
    {"code":"he","name":"Hebrew (modern)","nativeName":"עברית"},
    {"code":"hz","name":"Herero","nativeName":"Otjiherero"},
    {"code":"hi","name":"Hindi","nativeName":"हिन्दी, हिंदी"},
    {"code":"ho","name":"Hiri Motu","nativeName":"Hiri Motu"},
    {"code":"hu","name":"Hungarian","nativeName":"Magyar"},
    {"code":"ia","name":"Interlingua","nativeName":"Interlingua"},
    {"code":"id","name":"Indonesian","nativeName":"Bahasa Indonesia"},
    {"code":"ie","name":"Interlingue","nativeName":"Originally called Occidental; then Interlingue after WWII"},
    {"code":"ga","name":"Irish","nativeName":"Gaeilge"},
    {"code":"ig","name":"Igbo","nativeName":"Asụsụ Igbo"},
    {"code":"ik","name":"Inupiaq","nativeName":"Iñupiaq, Iñupiatun"},
    {"code":"io","name":"Ido","nativeName":"Ido"},
    {"code":"is","name":"Icelandic","nativeName":"Íslenska"},
    {"code":"it","name":"Italian","nativeName":"Italiano"},
    {"code":"iu","name":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
    {"code":"ja","name":"Japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
    {"code":"jv","name":"Javanese","nativeName":"basa Jawa"},
    {"code":"kl","name":"Kalaallisut, Greenlandic","nativeName":"kalaallisut, kalaallit oqaasii"},
    {"code":"kn","name":"Kannada","nativeName":"ಕನ್ನಡ"},
    {"code":"kr","name":"Kanuri","nativeName":"Kanuri"},
    {"code":"ks","name":"Kashmiri","nativeName":"कश्मीरी, كشميري‎"},
    {"code":"kk","name":"Kazakh","nativeName":"Қазақ тілі"},
    {"code":"km","name":"Khmer","nativeName":"ភាសាខ្មែរ"},
    {"code":"ki","name":"Kikuyu, Gikuyu","nativeName":"Gĩkũyũ"},
    {"code":"rw","name":"Kinyarwanda","nativeName":"Ikinyarwanda"},
    {"code":"ky","name":"Kirghiz, Kyrgyz","nativeName":"кыргыз тили"},
    {"code":"kv","name":"Komi","nativeName":"коми кыв"},
    {"code":"kg","name":"Kongo","nativeName":"KiKongo"},
    {"code":"ko","name":"Korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
    {"code":"ku","name":"Kurdish","nativeName":"Kurdî, كوردی‎"},
    {"code":"kj","name":"Kwanyama, Kuanyama","nativeName":"Kuanyama"},
    {"code":"la","name":"Latin","nativeName":"latine, lingua latina"},
    {"code":"lb","name":"Luxembourgish, Letzeburgesch","nativeName":"Lëtzebuergesch"},
    {"code":"lg","name":"Luganda","nativeName":"Luganda"},
    {"code":"li","name":"Limburgish, Limburgan, Limburger","nativeName":"Limburgs"},
    {"code":"ln","name":"Lingala","nativeName":"Lingála"},
    {"code":"lo","name":"Lao","nativeName":"ພາສາລາວ"},
    {"code":"lt","name":"Lithuanian","nativeName":"lietuvių kalba"},
    {"code":"lu","name":"Luba-Katanga","nativeName":""},
    {"code":"lv","name":"Latvian","nativeName":"latviešu valoda"},
    {"code":"gv","name":"Manx","nativeName":"Gaelg, Gailck"},
    {"code":"mk","name":"Macedonian","nativeName":"македонски јазик"},
    {"code":"mg","name":"Malagasy","nativeName":"Malagasy fiteny"},
    {"code":"ms","name":"Malay","nativeName":"bahasa Melayu, بهاس ملايو‎"},
    {"code":"ml","name":"Malayalam","nativeName":"മലയാളം"},
    {"code":"mt","name":"Maltese","nativeName":"Malti"},
    {"code":"mi","name":"Māori","nativeName":"te reo Māori"},
    {"code":"mr","name":"Marathi (Marāṭhī)","nativeName":"मराठी"},
    {"code":"mh","name":"Marshallese","nativeName":"Kajin M̧ajeļ"},
    {"code":"mn","name":"Mongolian","nativeName":"монгол"},
    {"code":"na","name":"Nauru","nativeName":"Ekakairũ Naoero"},
    {"code":"nv","name":"Navajo, Navaho","nativeName":"Diné bizaad, Dinékʼehǰí"},
    {"code":"nb","name":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
    {"code":"nd","name":"North Ndebele","nativeName":"isiNdebele"},
    {"code":"ne","name":"Nepali","nativeName":"नेपाली"},
    {"code":"ng","name":"Ndonga","nativeName":"Owambo"},
    {"code":"nn","name":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
    {"code":"no","name":"Norwegian","nativeName":"Norsk"},
    {"code":"ii","name":"Nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
    {"code":"nr","name":"South Ndebele","nativeName":"isiNdebele"},
    {"code":"oc","name":"Occitan","nativeName":"Occitan"},
    {"code":"oj","name":"Ojibwe, Ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
    {"code":"cu","name":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic","nativeName":"ѩзыкъ словѣньскъ"},
    {"code":"om","name":"Oromo","nativeName":"Afaan Oromoo"},
    {"code":"or","name":"Oriya","nativeName":"ଓଡ଼ିଆ"},
    {"code":"os","name":"Ossetian, Ossetic","nativeName":"ирон æвзаг"},
    {"code":"pa","name":"Panjabi, Punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی‎"},
    {"code":"pi","name":"Pāli","nativeName":"पाऴि"},
    {"code":"fa","name":"Persian","nativeName":"فارسی"},
    {"code":"pl","name":"Polish","nativeName":"polski"},
    {"code":"ps","name":"Pashto, Pushto","nativeName":"پښتو"},
    {"code":"pt","name":"Portuguese","nativeName":"Português"},
    {"code":"qu","name":"Quechua","nativeName":"Runa Simi, Kichwa"},
    {"code":"rm","name":"Romansh","nativeName":"rumantsch grischun"},
    {"code":"rn","name":"Kirundi","nativeName":"kiRundi"},
    {"code":"ro","name":"Romanian, Moldavian, Moldovan","nativeName":"română"},
    {"code":"ru","name":"Russian","nativeName":"русский язык"},
    {"code":"sa","name":"Sanskrit (Saṁskṛta)","nativeName":"संस्कृतम्"},
    {"code":"sc","name":"Sardinian","nativeName":"sardu"},
    {"code":"sd","name":"Sindhi","nativeName":"सिन्धी, سنڌي، سندھی‎"},
    {"code":"se","name":"Northern Sami","nativeName":"Davvisámegiella"},
    {"code":"sm","name":"Samoan","nativeName":"gagana faa Samoa"},
    {"code":"sg","name":"Sango","nativeName":"yângâ tî sängö"},
    {"code":"sr","name":"Serbian","nativeName":"српски језик"},
    {"code":"gd","name":"Scottish Gaelic; Gaelic","nativeName":"Gàidhlig"},
    {"code":"sn","name":"Shona","nativeName":"chiShona"},
    {"code":"si","name":"Sinhala, Sinhalese","nativeName":"සිංහල"},
    {"code":"sk","name":"Slovak","nativeName":"slovenčina"},
    {"code":"sl","name":"Slovene","nativeName":"slovenščina"},
    {"code":"so","name":"Somali","nativeName":"Soomaaliga, af Soomaali"},
    {"code":"st","name":"Southern Sotho","nativeName":"Sesotho"},
    {"code":"es","name":"Spanish; Castilian","nativeName":"español, castellano"},
    {"code":"su","name":"Sundanese","nativeName":"Basa Sunda"},
    {"code":"sw","name":"Swahili","nativeName":"Kiswahili"},
    {"code":"ss","name":"Swati","nativeName":"SiSwati"},
    {"code":"sv","name":"Swedish","nativeName":"svenska"},
    {"code":"ta","name":"Tamil","nativeName":"தமிழ்"},
    {"code":"te","name":"Telugu","nativeName":"తెలుగు"},
    {"code":"tg","name":"Tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی‎"},
    {"code":"th","name":"Thai","nativeName":"ไทย"},
    {"code":"ti","name":"Tigrinya","nativeName":"ትግርኛ"},
    {"code":"bo","name":"Tibetan Standard, Tibetan, Central","nativeName":"བོད་ཡིག"},
    {"code":"tk","name":"Turkmen","nativeName":"Türkmen, Түркмен"},
    {"code":"tl","name":"Tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
    {"code":"tn","name":"Tswana","nativeName":"Setswana"},
    {"code":"to","name":"Tonga (Tonga Islands)","nativeName":"faka Tonga"},
    {"code":"tr","name":"Turkish","nativeName":"Türkçe"},
    {"code":"ts","name":"Tsonga","nativeName":"Xitsonga"},
    {"code":"tt","name":"Tatar","nativeName":"татарча, tatarça, تاتارچا‎"},
    {"code":"tw","name":"Twi","nativeName":"Twi"},
    {"code":"ty","name":"Tahitian","nativeName":"Reo Tahiti"},
    {"code":"ug","name":"Uighur, Uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
    {"code":"uk","name":"Ukrainian","nativeName":"українська"},
    {"code":"ur","name":"Urdu","nativeName":"اردو"},
    {"code":"uz","name":"Uzbek","nativeName":"zbek, Ўзбек, أۇزبېك‎"},
    {"code":"ve","name":"Venda","nativeName":"Tshivenḓa"},
    {"code":"vi","name":"Vietnamese","nativeName":"Tiếng Việt"},
    {"code":"vo","name":"Volapük","nativeName":"Volapük"},
    {"code":"wa","name":"Walloon","nativeName":"Walon"},
    {"code":"cy","name":"Welsh","nativeName":"Cymraeg"},
    {"code":"wo","name":"Wolof","nativeName":"Wollof"},
    {"code":"fy","name":"Western Frisian","nativeName":"Frysk"},
    {"code":"xh","name":"Xhosa","nativeName":"isiXhosa"},
    {"code":"yi","name":"Yiddish","nativeName":"ייִדיש"},
    {"code":"yo","name":"Yoruba","nativeName":"Yorùbá"},
    {"code":"za","name":"Zhuang, Chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
]; */

// export const countryList = [
//     { "value": "AD", "label": "Andorra" },
//     { "value": "AE", "label": "United Arab Emirates" },
//     { "value": "AF", "label": "Afghanistan" },
//     { "value": "AG", "label": "Antigua and Barbuda" },
//     { "value": "AI", "label": "Anguilla" },
//     { "value": "AL", "label": "Albania" },
//     { "value": "AM", "label": "Armenia" },
//     { "value": "AO", "label": "Angola" },
//     { "value": "AQ", "label": "Antarctica" },
//     { "value": "AR", "label": "Argentina" },
//     { "value": "AS", "label": "American Samoa" },
//     { "value": "AT", "label": "Austria" },
//     { "value": "AU", "label": "Australia" },
//     { "value": "AW", "label": "Aruba" },
//     { "value": "AX", "label": "Åland Islands" },
//     { "value": "AZ", "label": "Azerbaijan" },
//     { "value": "BA", "label": "Bosnia and Herzegovina" },
//     { "value": "BB", "label": "Barbados" },
//     { "value": "BD", "label": "Bangladesh" },
//     { "value": "BE", "label": "Belgium" },
//     { "value": "BF", "label": "Burkina Faso" },
//     { "value": "BG", "label": "Bulgaria" },
//     { "value": "BH", "label": "Bahrain" },
//     { "value": "BI", "label": "Burundi" },
//     { "value": "BJ", "label": "Benin" },
//     { "value": "BL", "label": "Saint Barthélemy" },
//     { "value": "BM", "label": "Bermuda" },
//     { "value": "BN", "label": "Brunei Darussalam" },
//     { "value": "BO", "label": "Bolivia, Plurinational State of" },
//     { "value": "BQ", "label": "Bonaire, Sint Eustatius and Saba" },
//     { "value": "BR", "label": "Brazil" },
//     { "value": "BS", "label": "Bahamas" },
//     { "value": "BT", "label": "Bhutan" },
//     { "value": "BV", "label": "Bouvet Island" },
//     { "value": "BW", "label": "Botswana" },
//     { "value": "BY", "label": "Belarus" },
//     { "value": "BZ", "label": "Belize" },
//     { "value": "CA", "label": "Canada" },
//     { "value": "CC", "label": "Cocos (Keeling) Islands" },
//     { "value": "CD", "label": "Congo, Democratic Republic of the" },
//     { "value": "CF", "label": "Central African Republic" },
//     { "value": "CG", "label": "Congo" },
//     { "value": "CH", "label": "Switzerland" },
//     { "value": "CI", "label": "Côte d'Ivoire" },
//     { "value": "CK", "label": "Cook Islands" },
//     { "value": "CL", "label": "Chile" },
//     { "value": "CM", "label": "Cameroon" },
//     { "value": "CN", "label": "China" },
//     { "value": "CO", "label": "Colombia" },
//     { "value": "CR", "label": "Costa Rica" },
//     { "value": "CU", "label": "Cuba" },
//     { "value": "CV", "label": "Cabo Verde" },
//     { "value": "CW", "label": "Curaçao" },
//     { "value": "CX", "label": "Christmas Island" },
//     { "value": "CY", "label": "Cyprus" },
//     { "value": "CZ", "label": "Czechia" },
//     { "value": "DE", "label": "Germany" },
//     { "value": "DJ", "label": "Djibouti" },
//     { "value": "DK", "label": "Denmark" },
//     { "value": "DM", "label": "Dominica" },
//     { "value": "DO", "label": "Dominican Republic" },
//     { "value": "DZ", "label": "Algeria" },
//     { "value": "EC", "label": "Ecuador" },
//     { "value": "EE", "label": "Estonia" },
//     { "value": "EG", "label": "Egypt" },
//     { "value": "EH", "label": "Western Sahara" },
//     { "value": "ER", "label": "Eritrea" },
//     { "value": "ES", "label": "Spain" },
//     { "value": "ET", "label": "Ethiopia" },
//     { "value": "FI", "label": "Finland" },
//     { "value": "FJ", "label": "Fiji" },
//     { "value": "FK", "label": "Falkland Islands (Malvinas)" },
//     { "value": "FM", "label": "Micronesia, Federated States of" },
//     { "value": "FO", "label": "Faroe Islands" },
//     { "value": "FR", "label": "France" },
//     { "value": "GA", "label": "Gabon" },
//     { "value": "GB", "label": "United Kingdom of Great Britain and Northern Ireland" },
//     { "value": "GD", "label": "Grenada" },
//     { "value": "GE", "label": "Georgia" },
//     { "value": "GF", "label": "French Guiana" },
//     { "value": "GG", "label": "Guernsey" },
//     { "value": "GH", "label": "Ghana" },
//     { "value": "GI", "label": "Gibraltar" },
//     { "value": "GL", "label": "Greenland" },
//     { "value": "GM", "label": "Gambia" },
//     { "value": "GN", "label": "Guinea" },
//     { "value": "GP", "label": "Guadeloupe" },
//     { "value": "GQ", "label": "Equatorial Guinea" },
//     { "value": "GR", "label": "Greece" },
//     { "value": "GS", "label": "South Georgia and the South Sandwich Islands" },
//     { "value": "GT", "label": "Guatemala" },
//     { "value": "GU", "label": "Guam" },
//     { "value": "GW", "label": "Guinea-Bissau" },
//     { "value": "GY", "label": "Guyana" },
//     { "value": "HK", "label": "Hong Kong" },
//     { "value": "HM", "label": "Heard Island and McDonald Islands" },
//     { "value": "HN", "label": "Honduras" },
//     { "value": "HR", "label": "Croatia" },
//     { "value": "HT", "label": "Haiti" },
//     { "value": "HU", "label": "Hungary" },
//     { "value": "ID", "label": "Indonesia" },
//     { "value": "IE", "label": "Ireland" },
//     { "value": "IL", "label": "Israel" },
//     { "value": "IM", "label": "Isle of Man" },
//     { "value": "IN", "label": "India" },
//     { "value": "IO", "label": "British Indian Ocean Territory" },
//     { "value": "IQ", "label": "Iraq" },
//     { "value": "IR", "label": "Iran, Islamic Republic of" },
//     { "value": "IS", "label": "Iceland" },
//     { "value": "IT", "label": "Italy" },
//     { "value": "JE", "label": "Jersey" },
//     { "value": "JM", "label": "Jamaica" },
//     { "value": "JO", "label": "Jordan" },
//     { "value": "JP", "label": "Japan" },
//     { "value": "KE", "label": "Kenya" },
//     { "value": "KG", "label": "Kyrgyzstan" },
//     { "value": "KH", "label": "Cambodia" },
//     { "value": "KI", "label": "Kiribati" },
//     { "value": "KM", "label": "Comoros" },
//     { "value": "KN", "label": "Saint Kitts and Nevis" },
//     { "value": "KP", "label": "Korea, Democratic People's Republic of" },
//     { "value": "KR", "label": "Korea, Republic of" },
//     { "value": "KW", "label": "Kuwait" },
//     { "value": "KY", "label": "Cayman Islands" },
//     { "value": "KZ", "label": "Kazakhstan" },
//     { "value": "LA", "label": "Lao People's Democratic Republic" },
//     { "value": "LB", "label": "Lebanon" },
//     { "value": "LC", "label": "Saint Lucia" },
//     { "value": "LI", "label": "Liechtenstein" },
//     { "value": "LK", "label": "Sri Lanka" },
//     { "value": "LR", "label": "Liberia" },
//     { "value": "LS", "label": "Lesotho" },
//     { "value": "LT", "label": "Lithuania" },
//     { "value": "LU", "label": "Luxembourg" },
//     { "value": "LV", "label": "Latvia" },
//     { "value": "LY", "label": "Libya" },
//     { "value": "MA", "label": "Morocco" },
//     { "value": "MC", "label": "Monaco" },
//     { "value": "MD", "label": "Moldova, Republic of" },
//     { "value": "ME", "label": "Montenegro" },
//     { "value": "MF", "label": "Saint Martin, (French part)" },
//     { "value": "MG", "label": "Madagascar" },
//     { "value": "MH", "label": "Marshall Islands" },
//     { "value": "MK", "label": "North Macedonia" },
//     { "value": "ML", "label": "Mali" },
//     { "value": "MM", "label": "Myanmar" },
//     { "value": "MN", "label": "Mongolia" },
//     { "value": "MO", "label": "Macao" },
//     { "value": "MP", "label": "Northern Mariana Islands" },
//     { "value": "MQ", "label": "Martinique" },
//     { "value": "MR", "label": "Mauritania" },
//     { "value": "MS", "label": "Montserrat" },
//     { "value": "MT", "label": "Malta" },
//     { "value": "MU", "label": "Mauritius" },
//     { "value": "MV", "label": "Maldives" },
//     { "value": "MW", "label": "Malawi" },
//     { "value": "MX", "label": "Mexico" },
//     { "value": "MY", "label": "Malaysia" },
//     { "value": "MZ", "label": "Mozambique" },
//     { "value": "NA", "label": "Namibia" },
//     { "value": "NC", "label": "New Caledonia" },
//     { "value": "NE", "label": "Niger" },
//     { "value": "NF", "label": "Norfolk Island" },
//     { "value": "NG", "label": "Nigeria" },
//     { "value": "NI", "label": "Nicaragua" },
//     { "value": "NL", "label": "Netherlands" },
//     { "value": "NO", "label": "Norway" },
//     { "value": "NP", "label": "Nepal" },
//     { "value": "NR", "label": "Nauru" },
//     { "value": "NU", "label": "Niue" },
//     { "value": "NZ", "label": "New Zealand" },
//     { "value": "OM", "label": "Oman" },
//     { "value": "PA", "label": "Panama" },
//     { "value": "PE", "label": "Peru" },
//     { "value": "PF", "label": "French Polynesia" },
//     { "value": "PG", "label": "Papua New Guinea" },
//     { "value": "PH", "label": "Philippines" },
//     { "value": "PK", "label": "Pakistan" },
//     { "value": "PL", "label": "Poland" },
//     { "value": "PM", "label": "Saint Pierre and Miquelon" },
//     { "value": "PN", "label": "Pitcairn" },
//     { "value": "PR", "label": "Puerto Rico" },
//     { "value": "PS", "label": "Palestine, State of" },
//     { "value": "PT", "label": "Portugal" },
//     { "value": "PW", "label": "Palau" },
//     { "value": "PY", "label": "Paraguay" },
//     { "value": "QA", "label": "Qatar" },
//     { "value": "RE", "label": "Réunion" },
//     { "value": "RO", "label": "Romania" },
//     { "value": "RS", "label": "Serbia" },
//     { "value": "RU", "label": "Russian Federation" },
//     { "value": "RW", "label": "Rwanda" },
//     { "value": "SA", "label": "Saudi Arabia" },
//     { "value": "SB", "label": "Solomon Islands" },
//     { "value": "SC", "label": "Seychelles" },
//     { "value": "SD", "label": "Sudan" },
//     { "value": "SE", "label": "Sweden" },
//     { "value": "SG", "label": "Singapore" },
//     { "value": "SH", "label": "Saint Helena, Ascension and Tristan da Cunha" },
//     { "value": "SI", "label": "Slovenia" },
//     { "value": "SJ", "label": "Svalbard and Jan Mayen" },
//     { "value": "SK", "label": "Slovakia" },
//     { "value": "SL", "label": "Sierra Leone" },
//     { "value": "SM", "label": "San Marino" },
//     { "value": "SN", "label": "Senegal" },
//     { "value": "SO", "label": "Somalia" },
//     { "value": "SR", "label": "Suriname" },
//     { "value": "SS", "label": "South Sudan" },
//     { "value": "ST", "label": "Sao Tome and Principe" },
//     { "value": "SV", "label": "El Salvador" },
//     { "value": "SX", "label": "Sint Maarten, (Dutch part)" },
//     { "value": "SY", "label": "Syrian Arab Republic" },
//     { "value": "SZ", "label": "Eswatini" },
//     { "value": "TC", "label": "Turks and Caicos Islands" },
//     { "value": "TD", "label": "Chad" },
//     { "value": "TF", "label": "French Southern Territories" },
//     { "value": "TG", "label": "Togo" },
//     { "value": "TH", "label": "Thailand" },
//     { "value": "TJ", "label": "Tajikistan" },
//     { "value": "TK", "label": "Tokelau" },
//     { "value": "TL", "label": "Timor-Leste" },
//     { "value": "TM", "label": "Turkmenistan" },
//     { "value": "TN", "label": "Tunisia" },
//     { "value": "TO", "label": "Tonga" },
//     { "value": "TR", "label": "Turkey" },
//     { "value": "TT", "label": "Trinidad and Tobago" },
//     { "value": "TV", "label": "Tuvalu" },
//     { "value": "TW", "label": "Taiwan, Province of China" },
//     { "value": "TZ", "label": "Tanzania, United Republic of" },
//     { "value": "UA", "label": "Ukraine" },
//     { "value": "UG", "label": "Uganda" },
//     { "value": "UM", "label": "United States Minor Outlying Islands" },
//     { "value": "US", "label": "United States of America" },
//     { "value": "UY", "label": "Uruguay" },
//     { "value": "UZ", "label": "Uzbekistan" },
//     { "value": "VA", "label": "Holy See" },
//     { "value": "VC", "label": "Saint Vincent and the Grenadines" },
//     { "value": "VE", "label": "Venezuela, Bolivarian Republic of" },
//     { "value": "VG", "label": "Virgin Islands, British" },
//     { "value": "VI", "label": "Virgin Islands, U.S." },
//     { "value": "VN", "label": "Viet Nam" },
//     { "value": "VU", "label": "Vanuatu" },
//     { "value": "WF", "label": "Wallis and Futuna" },
//     { "value": "WS", "label": "Samoa" },
//     { "value": "YE", "label": "Yemen" },
//     { "value": "YT", "label": "Mayotte" },
//     { "value": "ZA", "label": "South Africa" },
//     { "value": "ZM", "label": "Zambia" },
//     { "value": "ZW", "label": "Zimbabwe" }
// ]