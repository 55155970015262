import { useState, useContext, useEffect, useRef } from 'react'
import { DialogModes, FormContext } from '../../contexts'
import 'react-day-picker/dist/style.css';
import './styles.scss'
import { useElementDesign } from '../useElementDesign';
import { useMultiStep } from '../../hooks/useMultiStep';
import { InlineActionbarElementWrapper } from '../../components/InlineActionbarElementWrapper';
import { useElementStyle } from '../useElementStyle';
import InlineEditing from '../../components/InlineEditing';
import { getDialogValueKey } from '../../utils/features';

export default function DatePicker({ data, ...props }) {
  const formContext = useContext(FormContext);
  const { isEditMode, isPdfMode } = useContext(DialogModes);
  const elementDesign = useElementDesign(data.id, data);
  const [value, setValue] = useState();
  const multiStep = useMultiStep(data);
  const inputRef = 'date'

  const pickerRef = useRef();

  useEffect(() => {
    if(formContext?.inputValues[getDialogValueKey(data)]?.value)
      formContext?.reusablePropertyChanged(data.property, formContext?.inputValues[getDialogValueKey(data)]?.value)
  }, [])

  function onChange(value) {
    setValue(value)
    formContext?.updateValue(getDialogValueKey(data), value)
    formContext?.reusablePropertyChanged(data.property, value)
  }

  const elementStyle = useElementStyle(data);

  const { borderColor, borderWidth, width } = elementStyle.borderAndWidthStyle();
  const { justifyContent, ...fontStyles } = elementStyle.textStyle(data?.text, data?.text?.alignment);
  const labelStyle = elementStyle.textStyle(data?.labelStyle, data?.text?.alignment);
  const labelClass = elementStyle.textClassName(data?.labelStyle);

  const roundedCornerClass = data?.roundedCorners ? 'rounded-md' : ''
  const backgroundColorStyle = data?.backgroundColor ? data.backgroundColor : '#fff'

  const datePickerStyle = {
    ...fontStyles,
    borderWidth: 0,
    backgroundColor: backgroundColorStyle,
  }

  const val = formContext?.inputValues[getDialogValueKey(data)]?.value && new Date(formContext?.inputValues[getDialogValueKey(data)]?.value)?.toString() !== "Invalid Date"
    ? new Date(formContext?.inputValues[getDialogValueKey(data)]?.value)
    : value

  const inputErrorClasses = elementDesign.inputErrorClasses(data.requiredField, val)

  const inputWrapperStyle = {
    borderWidth: !inputErrorClasses ? borderWidth : undefined,
    borderColor: !inputErrorClasses ? borderColor : undefined,
    backgroundColor: backgroundColorStyle,
    width: '100%',
    maxWidth: width
  }

  const inputWrapperPdfStyle = {
    width: '100%',
    maxWidth: width
  }

  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 150;
  const toYear = 2100;

  const hideElement = elementDesign.hideConnectedElement(data?.connectedElement?.value, data?.connectedElement?.type)

  return (
    <InlineActionbarElementWrapper designElement={elementDesign} openElementProperties>
      <div
        className={`${hideElement && !isEditMode ? 'hidden' : ''} ${data?.labelTop ? "" : "sm:grid sm:grid-cols-2 sm:gap-6 sm:items-start print:grid print:grid-cols-2 print:items-start"}`}
        ref={pickerRef}
        id={data.id}
        onBlur={() => elementDesign.handleBlur(formContext?.inputValues[getDialogValueKey(data)]?.value, inputRef)}
      >
        <InlineEditing
          initData={data}
          style={labelStyle}
          classes={`sf-date-picker-label block text-sm text-gray-700 sm:mt-px sm:pt-2 ${labelClass}`}
          name='label'
          id={`label-${data.id}`}
        >
          <label style={{ wordBreak: 'break-word', ...labelStyle }} className={`sf-date-picker-label block text-sm text-gray-700 sm:mt-px sm:pt-2 ${labelClass}`}>
            {elementDesign.translateTerm(data.label)}
            {elementDesign.requiredFieldIndicator(data.requiredField)}
            {elementDesign.translateHelpText(data)}
          </label>
        </InlineEditing>
        {
          isPdfMode ?
          <div className='sm:mt-0 print:mt-0 max-w-xl w-full' style={inputWrapperPdfStyle}>
            <label className='element block max-w-xl w-full' style={fontStyles}>{val?.toLocaleDateString("en-GB") || "\u00A0"}</label>
          </div>
          :
          <div
            className={`sf-date-picker-input-wrapper sm:mt-0 print:mt-0 max-w-xl w-full ${(elementDesign.isReadOnly() || multiStep.isReadOnly) ? '' : 'cursor-pointer'} ${roundedCornerClass} ${inputErrorClasses} ${elementDesign.readOnlyElementClasses()} print:hidden`}
            style={inputWrapperStyle}
          >
            <input
              type="date"
              id={data.property}
              value={val ? val.toISOString().substring(0, 10) : ''}
              onChange={(e) => {onChange(e.target.value)}}
              style={datePickerStyle}
              disabled={elementDesign.isReadOnly() || multiStep.isReadOnly}
              readOnly={isEditMode}
            />
            {
              data?.trailingText &&
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span style={fontStyles} className="text-gray-500 sm:text-sm">
                  {elementDesign.translateTerm(data.trailingText, 'trailingText')}
                </span>
              </div>
            }
          </div>
        }
        {elementDesign.errorMessage()}
      </div>
    </InlineActionbarElementWrapper>
  )
}